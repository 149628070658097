import { clsx, type ClassValue } from 'clsx'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { twMerge } from 'tailwind-merge'

dayjs.extend(utc)
dayjs.extend(timezone)

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export const convertLocalToUTC = (date: string, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS') => {
    return dayjs.tz(date).utc().format(format)
}
